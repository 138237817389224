
import { defineComponent } from "vue";
// import { data } from "@/shared/notfound";
import CliveLogoLink from "@/components/atoms/link/CliveLogoLink/CliveLogoLink.vue";
import AppRouterLink from "@/components/atoms/link/AppRouterLink/AppRouterLink.vue";
export default defineComponent({
  name: "NotFound",
  components: { CliveLogoLink, AppRouterLink },
  setup() {
    const title = "카카오 i 커넥트 라이브에 문의 주셔서 감사합니다.";
    const description =
      "문의가 정상적으로 등록되었습니다. 빠른 시간 내에 카카오 i 커넥트 라이브 담당자가 연락드릴 예정입니다.";
    return {
      title,
      description
    };
  }
});
